<template>
  <span class="text-center">
    <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined   @click="dialog=!dialog" >
        {{ $store.getters.getTextMap().create_machine }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-engine-outline</v-icon>
      </v-btn>
         <v-bottom-sheet v-model="dialog">
      <v-sheet
     :dark="$store.getters.getColorPalette().isDark"
        class="text-center"
         :style="{background: $store.getters.getColorPalette().background2ColorCode}"
      >
      <CreateMachine  v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>

  </span>
  
</template>

<script>
import CreateMachine from '@/components/crud_components/CreateMachine'
 export default {
     name:'CreateMachineBottomSheet',
     components:{
         CreateMachine
     },
     data(){
         return {
             dialog:false
         }
     }
 }
 </script>