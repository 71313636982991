<template >
  <v-container fluid >
    <v-container class="py-1" fluid v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
      <v-row no-gutters>
        <v-col align="right" >
          <CreateMachineBottomSheet/>
        </v-col>
      </v-row>
    </v-container>
    <v-row no-gutters>
      <v-col>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
        <v-tabs show-arrows :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().backgroundColorCode" v-model="tab">
          <v-tabs-slider  :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
          <router-link  v-for="workspace in $store.state.workspaces" :key="workspace.workspace_id"  :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="`/machines/workspace/${workspace.workspace_id}`">
          <v-tab>{{workspace.name}}</v-tab>
          <!-- <v-tab v-for="workspace in $store.state.workspaces" :key="workspace.workspace_id">{{workspace.name}}</v-tab> -->
          </router-link>
        </v-tabs>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
      </v-col>
    </v-row>  
    <router-view></router-view>
  </v-container>
</template>
<!-- <v-row no-gutters>
  <v-col cols="12">
    <v-tabs-items v-model="tab">
<v-tab-item
v-for="workspace in $store.state.workspaces"
:key="workspace.workspace_id"
>

<MachinePage :workspace_id="workspace.workspace_id" ></MachinePage>
</v-tab-item>
    </v-tabs-items>
  </v-col>
</v-row>

</div> -->

<!-- </div> -->
<!-- </v-col>
</v-row>      -->
<!-- <v-row no-gutters>
<v-col cols="12">
<v-tabs-items v-model="tab">
<v-tab-item v-for="workspace in $store.state.workspaces" :key="workspace.workspace_id">
<router-link :to="`/workspace/${workspace.workspace_id}`">{{ workspace.workspace_id }}</router-link>
</v-tab-item>
</v-tabs-items>
</v-col>
</v-row> -->
<script>
import CreateMachineBottomSheet from '@/components/modals/CreateMachineBottomSheet'
// import MachinePage from '@/pages/MachinePage'

export default {
  name:'EntureMachines',
  components:{
    CreateMachineBottomSheet,
    // MachinePage
  },
  created() {
    // Redirect to the first workspace ID
    if (this.$store.state.workspaces.length > 0) {
      const firstWorkspaceId = this.$store.state.workspaces[0].workspace_id;
      this.$router.push(`/machines/workspace/${firstWorkspaceId}`);
    }
  },
  mounted() {
    document.title='Machines'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
  data(){
    return {
      tab:null,

    }
  },
  methods:{
    goto(p){
      console.log(p)
    }
  }
}
</script>